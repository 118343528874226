var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{ref:"table",attrs:{"headers":_vm.headers,"disable-sort":"","resource-path":"product-reviews","show-expand":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('action-delete',{attrs:{"action-url":("product-reviews/" + (item.id))}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.approved_at),expression:"item.approved_at"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.approve(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-thumbs-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Dezactiveaza Review ")])])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.approved_at),expression:"!item.approved_at"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary-color",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.disapprove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-thumbs-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Aproba Review ")])])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Nume")]),_c('td',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]),_c('tr',[_c('td',[_vm._v("Telefon")]),_c('td',[_vm._v(_vm._s(item.phone))])]),_c('tr',[_c('td',[_vm._v("Review")]),_c('td',[_vm._v(_vm._s(item.body))])])])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }