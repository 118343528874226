<template>
  <v-sheet>
    <cit-data-table
      ref="table"
      :headers="headers"
      disable-sort
      resource-path="product-reviews"
      show-expand
    >
      <template v-slot:item.actions="{item}">

        <action-delete :action-url="`product-reviews/${item.id}`"/>

        <span v-show="item.approved_at">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                icon
                @click="approve(item)"
              >
                <v-icon left>fal fa-thumbs-down</v-icon>
              </v-btn>
            </template>
            <span>
             Dezactiveaza Review
            </span>
          </v-tooltip>
        </span>
        <span v-show="!item.approved_at">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                icon
                @click="disapprove(item)"
                class="primary-color"
              >
                <v-icon left>fal fa-thumbs-up</v-icon>
              </v-btn>
            </template>
            <span>
             Aproba Review
            </span>
          </v-tooltip>
        </span>


      </template>

      <template v-slot:expanded-item="{item}">
        <v-simple-table class="my-4">
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Nume</td>
              <td>{{ item.first_name }} {{ item.last_name }}</td>
            </tr>
            <tr>
              <td>Telefon</td>
              <td>{{ item.phone }}</td>
            </tr>
            <tr>
              <td>Review</td>
              <td>{{ item.body }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  name: 'ExtraWarrantiesList',
  components: {
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Produs',
          value: 'product',
          display: 'product'
        },
        {
          text: 'Nume',
          value: 'first_name',
          width: '120'
        },
        {
          text: 'Prenume',
          value: 'last_name',
          width: '120'
        },
        {
          text: 'Telefon',
          value: 'phone',
          display: 'phone',
          width: '120'
        },
        {
          text: 'Nota acordata',
          value: 'score',
          width: '150px',
          align: 'center'
        }
      ]
    }
  },
  methods: {
    disapprove (item) {
      this.$refs.table.startLoading()
      this.$http.patch(`/product-reviews/${item.id}/approve`)
        .then(() => {
          this.$refs.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la dezaprobare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$refs.table.stopLoading()
        })
    },
    approve (item) {
      this.$refs.table.startLoading()
      this.$http.patch(`/product-reviews/${item.id}/disapprove`)
        .then(() => {
          this.$refs.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la aprobare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$refs.table.stopLoading()
        })
    }
  }
}
</script>
